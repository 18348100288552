.uploadMedia-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 165%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  animation: fadeIn 0.3s ease;
  overflow: hidden;
}

.uploadMedia-modal-content {
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  max-width: 80%;
  max-height: 100%;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.media-modal-close {
    align-self: flex-start;
    top: 10px;
    right: 10px;
    color: #6F8C8B;
    background: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
    z-index: 201;
}

.upload-media-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0px;
  max-height: calc(100vh - 150px); /* Constrain the form height to viewport */
}

.custom-file-upload {
  padding: 10px;
  background-color: #ebcd61;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 16px;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.caption-input {
  width: 90%;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
}

.image-preview {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 0%;
  margin-top: 5px;
}

/* .image-preview-container .media-clear-image-btn {
  position: absolute;
  top: 20px;
  right: 100px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  background-color: #ff5757;
} */

.uploadMedia-modal-content .media-submit-btn {
  background-color: #93AFAE;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
}

.uploadMedia-modal-content .media-submit-btn:hover {
  background-color: #6F8C8B;
}

.button-container {
  display: flex;
  justify-content: flex-start; /* Ensures buttons are spread evenly */
  align-items: center;
  gap: 10px; /* Optional: Adds space between buttons */
  margin-bottom: 10px;
}


/* @media (prefers-color-scheme: dark) {
  .uploadMedia-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .media-modal-close {
      color: #b3b3b3;
      background: transparent;
    }
  
  .upload-media-form {
    background-color: #121212;
  }
  
  .custom-file-upload {
    background-color: #333333;
    color: white;
  }
  
  .comment-input {
    border: 1px solid #ddd;
  }

  
  .image-preview-container .media-clear-image-btn {
    color: white;
    background-color: #ff5757;
  }
  
  .media-submit-btn {
    background-color: #ffdd57;
    color: #fff;
  }
  
  .media-submit-btn:hover {
    background-color: blue;
  }
} */