/* Modal Overlay */
.edit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  animation: fadeIn 0.3s ease;
  /* overflow: hidden; */
}

/* Modal Content */
.edit-modal-content {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  max-width: 80%;
  max-height: 80%;
  width: 100vw;
  position: relative; 
  background-color: #fff; 
}

/* Modal Close Button */
.EditProfile-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Form Group for Inputs */
.edit-profile-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 5px;
  margin-bottom: 0px;
  background-color: #fff; 
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-top: -20px;
}

.form-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 20px;
}

/* Profile Preview */
.profile-preview {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 0%;
  margin-top: 5px;
}

.modal-username {
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
}

/* Profile Preview */
.profile-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 10px;
  padding: 0;
  /* border: 2px solid #6F8C8B; */
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  background-color: #fff;
  padding: 0;
  width: 100%;
}

/* Buttons */
.edit-profile-form .save-btn {
  background-color: #93AFAE;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: -10px;
  font-size: 16px;
}

.edit-profile-form .save-btn:hover{
  background-color: #6F8C8B;
}

.save-btn:disabled {
  background-color: #ccc;
}

/* Optional fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


/* ------------------------------------dark mode -----------------------------------------------*/
/* @media (prefers-color-scheme: dark) {
  .edit-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
    .edit-modal-content {
    background-color: white;
    background-color: #121212; 
  }
  
  .edit-profile-form {
    background-color: #121212; 
  }
  
  .form-group input {
    border: 1px solid #ddd;
    background-color: black;
    color: white;
  }
  
  .profile-icon {
    border: 2px solid white;
  }
  
  .profile-header {
    background-color: #121212;
  }

  .save-btn {
    background-color: #007bff;
    color: white;
  }
  
  .save-btn:disabled {
    background-color: #ccc;
  }

} */