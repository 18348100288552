/* ItineraryPage.css */

.itinerary-page {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
  font-family: 'Roboto';
}

.itinerary-page h1 {
  text-align: center;
  color: #93AFAE;
  margin-bottom: 20px;
  font-size: 24px;
}

.day-section {
  margin-bottom: 40px;
}

.day-section h2 {
  font-size: 22px;
  color: #513A2C;
  border-bottom: 2px solid #93AFAE;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.events-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.event-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
}

.event-time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ebcd61;
  flex-shrink: 0;
}

.event-time p {
  margin: 0;
  font-size: 16px;
}

.event-details h3, .key-people-container h3, .key-locations-container h3 {
  margin: 0 0 5px 0;
  color: #513A2C;
  font-size: 18px;
}

.event-details p {
  margin: 3px 0;
  color: #513A2C;
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  
}

.event-details a {
  color: #93AFAE;
  text-decoration: none;
  word-wrap: break-word;
}

.event-details a:hover {
  text-decoration: underline;
}

.event-contact-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Key People Styles */
.key-people-container, .key-locations-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.person-card, .location-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;  
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
}

.person-card p, .location-card p {
  margin: 0px 0;
  color: #513A2C;
  display: flex;
  align-items: center;
  gap: 5px;
}

.person-card a {
  color: #93AFAE;
  text-decoration: none;
}

.person-card a:hover {
  text-decoration: underline;
}

.tabs-itinerary {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button-itinerary {
  flex: 1;
  padding: 10px 0px;
  margin: 0 5px;
  min-width: 120px; /* Ensures a minimum width for all buttons */
  max-width: 200px; /* Limits the maximum width */
  background-color: #93AFAE;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}

.tab-button-itinerary.active {
  background-color: #6F8C8B;
}

.tab-button-itinerary:hover {
  background-color: #6F8C8B;
}

.tab-content-itinerary {
  margin-top: 20px;
}

/* Dark Mode */
/* @media (prefers-color-scheme: dark) {
  .itinerary-page {
    background-color: #121212;
    color: #ffffff;
  }

  .itinerary-page h1 {
    color: #ebcd61;
  }

  .day-section h2 {
    color: #ebcd61;
    border-bottom: 2px solid #6F8C8B;
  }

  .event-card {
    background-color: #1e1e1e;
    border: 1px solid #444;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  }

  .event-time {
    color: #ebcd61;
  }

  .event-details h3 {
    color: #ebcd61;
  }

  .event-details p {
    color: #ccc;
  }

  .event-details a {
    color: #ebcd61;
  }

  .event-details a:hover {
    text-decoration: underline;
  }
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' 24;
}

.material-icons-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' 24;
} */
