.camera-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.filtered-image-canvas {
  width: 100px; /* Adjust width to your desired size */
  height: auto; /* Maintain aspect ratio */
  border: 1px solid #ccc; /* Optional: to visualize the canvas */
  margin-top: 10px; /* Optional: spacing */
}


.camera-modal-content {
  padding: 20px;
  background-color: #fffaf9;
  border-radius: 8px;
  max-width: 450px;
  max-height: 100%;
  width: 90vw;
  height: auto;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.camera-modal-content .media-modal-close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #6F8C8B;
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  z-index: 201;
}

.image-preview-container {
  max-height: 40vh;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.camera-preview-section .image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0%;
  display: block;
}

.camera-preview-section .caption-input {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
}

.media-submit-btn {
  background-color: #93AFAE;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
  transition: background-color 0.3s ease;
}

.media-submit-btn:hover {
  background-color: #6F8C8B;
}

.media-submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

