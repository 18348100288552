.voiceNote-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  animation: fadeIn 0.3s ease;
  overflow: hidden;
}

.voiceNote-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
}

.voiceNote-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.voiceNote-record-btn,
.voiceNote-play-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.voiceNote-redo-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #fbde76;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.voiceNote-redo-btn:hover {
  background-color: #ebcd61;
}
.voiceNote-record-btn:hover,
.voiceNote-play-btn:hover {
  background-color: #0056b3;
}

.voiceNote-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
.voiceNote-caption {
  margin-top: 20px;
  text-align: center;
}

.voiceNote-caption-input {
  width: 100%;
  height: 60px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
}

.voiceNote-submit-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #93AFAE;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.voiceNote-submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.voiceNote-submit-btn:hover:not(:disabled) {
  background-color: #6F8C8B;
}
.blinking-mic {
  animation: blink 1s infinite;
  color: red;
}

@keyframes blink {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.voiceNote-stop-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.voiceNote-stop-btn:hover {
  background-color: #cc0000;
}
