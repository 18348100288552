/* index.css or App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Global Styles */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Apply box-sizing model to all elements */
*, *::before, *::after {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif; /* Ensure the font is inherited globally */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Full viewport height */
  min-width: 100%;
}

/* Header Styles */
.App-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #FFFAF9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow here */
  height: 70px;
  font-family: 'Lobster';
  z-index: 2;
  margin-bottom: 0px;
}

.App-header button {
  /* flex: 1; */
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 2rem; /* Responsive font size */
  color: black;
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); */
}

.App-logo {
  height: 60px; /* Reduced height for mobile */
  width: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Add spacing */
}

.App-header h1 {
  margin: 0;
  font-size: 2rem; /* Responsive font size */
}

.tab-button img {
  width: 150px; /* Set image width */
  height: 150px; /* Set image height */
  margin-left: -20px;
  margin-top: 10px;
}

.tab-button span {
  display: flex; /* Ensures the icon itself is centered */
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tab-button span h1 {
  font-family: 'Lobster';
  /* font-weight: bold; */
  font-size: 34px;
  color: #C5B49A;
  margin: 0;
  text-align: center;
  letter-spacing: -1px;
}

.profile-picture {
  width: 50px; /* Small size */
  height: 50px;
  border-radius: 50%; /* Makes it a circle */
  object-fit: cover; /* Ensure the image covers the entire circle */
  border: 1px solid #513A2C; /* Optional: Add a white border for a cleaner look */
  cursor: pointer; /* Optional: Make it clickable */
}

.header-right {
  display: flex;
  align-items: center;
  gap: 0px; /* Add space between buttons */
  margin: 5px;
}

.header-right .tab-button {
  margin-right: 20px;
}

/* Media Feed Styles */
.media-feed {
  flex: 1;
  gap: 16px;
  /* height: calc(100vh - 90px); */
  padding-bottom: 150px;
  padding-top: 0px;
  margin-top: 10px;
  max-width: 100%;
  overflow-y: auto; /* Ensure scroll is enabled if needed */
  z-index: 100;
  font-family: 'Roboto', sans-serif;
}

/* Media Item Styles */
.media-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0;
  width: 100%;
  flex-grow: 1;
}

.media-item img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}


/* Tabs Styles */
.tabs {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #FFFAF9;
  padding: 0px; /* Padding for touch area */
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.tabs button, .header-right button {
  flex: 1;
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.tabs button.active {
  background-color: #FFFAF9;
  color: #fff;
}

.material-symbols-outlined {
  font-weight: 1;
}

.large-icon {
  font-size: 30px;
  color: #513A2C;
  font-weight: 1;
  /* text-shadow: 2px 2px 5px #FFCEC8; */
}

/* Tab content should fill the available space */
.tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push the form to the bottom */
}

.main-content {
  flex: 1; /* This makes sure it takes up all available space except the footer */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes form to the bottom */
  /* align-items: flex-start; */
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  background-color: #f9f9f9; /* Optional: background color */
  margin-bottom: 60px; /* Space for the tab buttons */
}

/* Adjust form elements as needed */
form input, form textarea, form button {
  margin-bottom: 0px; /* Space between elements */
}

form button {
  align-self: center; /* Center button */
  padding: 10px 20px;
  background-color: #FFCEC8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

form button.clicked {
  background-color: #ddd;
  transform: scale(0.98);
}

.upload-media-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #f9f9f9; /* Optional background color */
  bottom: 10px; /* Adjust according to the height of your tabs */
}

.upload-media-form input[type="file"], 
.upload-media-form textarea, 
.upload-media-form button {
  margin-bottom: 10px; /* Space between elements */
}

.upload-media-form button {
  align-self: center; /* Center button */
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prompt-display-container {
  display: flex;
  align-items: flex-start; /* Align items vertically */
  justify-content: space-between; /* Space out items */
  gap: 20px; /* Space between chat bubble and button */
  padding: 10px;
}

.prompt-display-container button {
  display: flex;
  align-self: center; /* Center the button horizontally */
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: transparent; /* Background color for the button */
  border: none;
  cursor: pointer;
  color: #0095f6;
  font-size: 16px;
}


/* Styling for back button */
.back-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  color: #007BFF;
}

/* Styling for media list when a single prompt is selected */
.media-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.icon-share .small-icon {
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px;
}


/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.profile-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
}

/* Disable form while uploading */
button[disabled] {
  background-color: grey;
  cursor: not-allowed;
}





/* ------------------------------------dark mode -----------------------------------------------*/
/* @media (prefers-color-scheme: dark) {
  .App-header {
    background-color: #F0F0F0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .App-header button {
    color: white;
  }

  .profile-picture {
    border: 2px solid #F83908; 
  }
  
  .tabs {
    background-color: white;
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.1);
  }
  
  .tabs button.active {
    background-color: #FFCEC8;
    color: white;
  }
  .large-icon {
    color: #F83908;
    text-shadow: none;
  }

  form {
    background-color: white;
  }
  
  form button {
    background-color: white;
    color: #fff;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  form button.clicked {
    background-color: #ddd;
    transform: scale(0.98);
  }
} */

/* Styles for screens up to 768px (mobile and small tablets) */
@media (max-width: 768px) {
  .media-feed {
    align-self: center;    
    max-width: 100rem;    
  }
}

/* Styles for screens from 768px to 1024px (tablets) */
@media (min-width: 768px) and (max-width: 1024px) {
  .media-feed {
    align-self: center;    
    max-width: 30rem;
  }
}

/* Styles for screens 1024px and above (laptops and desktops) */
@media (min-width: 1024px) {
  .media-feed {
    align-self: center;    
    max-width: 40rem;
  }
}