.fab-main {
  background-color: #93AFAE;
  border: none;
  border-radius: 10%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  /* font-size: 24px; */
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.3);
  transition: transform 0.3s ease;
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 110;
}

.fab-main .material-icons {
  font-size: 36px;
  color: #FFFFFF;
}

.media-option .material-icons {
  font-size: 28px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  animation: fadeIn 0.3s ease;
  overflow: hidden;
}

/* Modal Content */
.modal-content {
  background-color: #FFFAF9;
  padding: 0px;
  border-radius: 8px;
  text-align: center;
  max-width: 70%;
  max-height: 100%;
  width: 100%;
  position: relative;  
  font-size: 16px;
}

/* Modal Close Button */
.modal-close {
  position: absolute;
  top: -15px;
  right: -10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 15px;
  color: #513A2C;
}

.media-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 50px;
}

.media-option {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 20px 0px 0px 0px;
  background-color: #FFFAF9;
  color: #513A2C;
  border-radius: 8px; 
  cursor: pointer;
  width: 100%;
  border: 2px solid #513A2C;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.media-option p {
  margin-left: 10px;
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* ------------------------------dark mode ------------------------------------- */
/* @media (prefers-color-scheme: dark) { */
  /* .fab-main {
    background: linear-gradient(45deg, #ff9318, #ff679c, #df2a2a, #ffc8ed);
    color: #E0E0E0;
  }

  .fab-main:hover{
    background-color: darkred;
  }
  
  .fab-main .material-icons {
    color: white;
  }
  
  .modal-overlay {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .modal-content {
    background-color: white;
  }
  
  .modal-close {
    color: #F83908;
  }
  
  .media-option {
    color: #F83908;
    background-color: #FFCEC8;
    border: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  } */
/* } */